<template>
  <div class="myAnswer flexC">
    <Breadcrumb class="custom-breadcrumb" ref="breadcrumb">
      <BreadcrumbItem>学习任务管理</BreadcrumbItem>
      <BreadcrumbItem>学习管理</BreadcrumbItem>
      <BreadcrumbItem :to="{ path: '/learning/taskDetail?userId=' + userId }">任务详情</BreadcrumbItem>
      <BreadcrumbItem>答题详情</BreadcrumbItem>
    </Breadcrumb>
    <div class="myAnswerList">
      <vueScroll :ops="ops">
        <div style="padding-right: 50px">
          <div class="examName" :style="!$route.query.IsAnswerExam ? 'margin-bottom: 50px' : ''">
            <span>{{ examInfo.examName }}</span>
            <label v-if="$route.query.IsAnswerExam">
              <Select v-model="optionValue" class="selectStyle" @on-change="selectChange($event, index)">
                <Option v-for="(time, index) in examInfo.localTime" :value="time.value" :key="'info5' + index"
                  >{{ $formatTime(time.label, 'yyyy-MM-dd') }}
                </Option>
              </Select>
            </label>
          </div>
          <div class="takeTime" v-if="$route.query.IsAnswerExam">
            <span><img src="../../../assets/start.png" alt="" />考试时间:&nbsp;{{ $formatTime(examInfo.examTime, 'yyyy-MM-dd hh:mm') }}</span>
            <span v-if="examInfo.endExamTime"
              ><img src="../../../assets/start.png" alt="" />结束考试时间:&nbsp;{{ $formatTime(examInfo.endExamTime, 'yyyy-MM-dd hh:mm') }}</span
            >
            <span><img src="../../../assets/finish.png" alt="" />用时:&nbsp;{{ examInfo.spendExamTime }}</span>
            <span><img src="../../../assets/score.png" alt="" />得分:&nbsp;{{ examInfo.score }}</span>
          </div>
          <hr class="hrStyle" />
        </div>
        <div class="examTopicList">
          <ul>
            <li class="typeByTopic" v-for="(q, index) in questionList" :key="index">
              <p class="topicType">{{ q.nameType }}(共{{ q.questionVOS.length }}题, 共{{ q.totalPoints }}分)</p>
              <div v-for="(vo, topicId) in q.questionVOS" :key="topicId" style="margin: 40px 0 20px 0">
                <p class="p1style">
                  {{ topicId + 1 }}、<span style="white-space: pre-line" v-html="vo.question.stem" />&nbsp;&nbsp;<span class="blueFont"
                    >分值: {{ vo.points }}分</span
                  >
                </p>
                <div v-if="q.name === '判断题'">
                  <Radio disabled style="margin-top: 20px">正确</Radio>
                  <br />
                  <Radio disabled style="margin-top: 20px">错误</Radio>
                </div>
                <p v-else class="p1style" v-for="(o, opId) in vo.question.options" :key="'info1-' + opId" style="margin-top: 20px">
                  {{ q.name === '填空题' ? opId + 1 : String.fromCharCode(opId + 65) }}.<span v-html="o.content || o.blankAnswer[0]" />
                </p>
                <div v-if="IsAnswerExam">
                  <p v-if="q.questionType === 0 || vo.questionType === 0" style="margin: 20px 0;color: #3C88D3">
                    <span
                      >考生答案:&nbsp;<span :style="{ color: vo.color }"
                        >{{ vo.myAnswer || '暂未作答' }}&nbsp;(<span :style="{ color: vo.color }">{{ vo.result ? '正确' : '错误' }}</span
                        >)</span
                      ></span
                    >
                    &nbsp;&nbsp;&nbsp;&nbsp;<span
                      >正确答案:&nbsp;<span>{{ vo.question.answer ? 'true' : 'false' }}</span></span
                    >
                  </p>
                  <p v-if="q.questionType === 3 || vo.questionType === 3" style="margin: 20px 0;color: #3C88D3">
                    <span
                      >考生答案:&nbsp;
                      <span :style="{ color: vo.color }"
                        ><span v-if="vo.myAnswer === '' || !vo.myAnswer">暂未作答</span>
                        <span v-else v-for="(i, key, index) in JSON.parse(vo.myAnswer)" :key="'info1-' + key"
                          >{{ index === 0 ? '' : '、 ' }}{{ i[0] }}</span
                        >&nbsp; (<span :style="{ color: vo.color }">{{ vo.result ? '正确' : '错误' }}</span
                        >)</span
                      ></span
                    >
                    &nbsp;&nbsp;&nbsp;&nbsp;<span
                      >正确答案:&nbsp;<span
                        ><span v-for="(an, index) in vo.question.answer" :key="'info1-' + index"
                          >{{ index + 1 }}、{{ an.answer[0] }}&nbsp;&nbsp;</span
                        ></span
                      ></span
                    >
                  </p>
                  <p v-if="q.questionType === 1 || vo.questionType === 1" style="margin: 20px 0;color: #3C88D3">
                    {{ vo.myAnswerByIndex }}
                    <span
                      >考生答案:&nbsp;<span :style="{ color: vo.color }"
                        >{{ vo.myAnswerByIndex !== undefined ? String.fromCharCode(vo.myAnswerByIndex + 65) : '暂未作答' }}&nbsp;({{
                          vo.result ? '正确' : '错误'
                        }})</span
                      ></span
                    >
                    &nbsp;&nbsp;&nbsp;&nbsp;<span
                      >正确答案:&nbsp;<span>{{ String.fromCharCode(vo.answer + 65) }}</span></span
                    >
                  </p>
                  <p v-if="q.questionType === 2 || vo.questionType === 2" style="margin: 20px 0;color: #3C88D3">
                    <span
                      >考生答案:&nbsp;<span :style="{ color: vo.color }"
                        ><span v-if="vo.myAnswerByIndex.length === 0">暂未作答</span
                        ><span v-else v-for="(myVo, index) in vo.myAnswerByIndex" :key="'myVo-' + index"
                          >{{ String.fromCharCode(myVo + 65) }}<template v-if="index !== vo.myAnswerByIndex.length - 1">、&nbsp;</template></span
                        >&nbsp;({{ vo.result ? '正确' : '错误' }})</span
                      ></span
                    >
                    &nbsp;&nbsp;&nbsp;&nbsp;<span
                      >正确答案:&nbsp;<span v-for="(mVo, index) in vo.answer" :key="'mVo-' + index"
                        >{{ String.fromCharCode(mVo + 65) }}<template v-if="index !== vo.answer.length - 1">、&nbsp;</template></span
                      ></span
                    >
                  </p>
                  <p v-if="q.questionType === 4 || vo.questionType === 4" style="margin: 20px 0;color: #3C88D3">
                    <span
                      >考生答案:&nbsp;<span :style="{ color: vo.color }" v-html="vo.myAnswer || '暂未作答'"></span>&nbsp;(<span
                        :style="{ color: vo.color }"
                        >{{ vo.myPoints + '分' }}</span
                      >)</span
                    >
                    &nbsp;&nbsp;&nbsp;&nbsp;<br /><span
                      >正确答案:&nbsp;<span>{{ vo.answer || '暂无答案' }}</span></span
                    >
                  </p>
                  <p v-if="q.questionType === 5 || vo.questionType === 5" style="margin: 20px 0;color: #3C88D3">
                    <span
                      >考生答案:&nbsp;<span :style="{ color: vo.color }" v-html="vo.myAnswer || '暂未作答'"
                        >&nbsp;(<span :style="{ color: vo.color }">{{ vo.result ? '正确' : '错误' }}</span
                        >)</span
                      ></span
                    >
                    &nbsp;&nbsp;&nbsp;&nbsp;<br /><span
                      >正确答案:&nbsp;<span>{{ vo.answer || '暂无答案' }}</span></span
                    >
                  </p>
                  <ul class="tagUl p2Style">
                    <li>标签:&nbsp;</li>
                    <li v-for="(k, knowId) in vo.question.knowledgePoints" :key="'k-' + knowId" style="padding: 5px 10px;">
                      <span>{{ k.knowledgeName }}</span>
                    </li>
                  </ul>
                  <p class="p2Style"><span>解析:&nbsp;</span><span v-html="vo.question.analysis || '暂无解析'" /></p>
                </div>
              </div>
              <hr class="hrStyle" />
            </li>
          </ul>
        </div>
      </vueScroll>
    </div>
  </div>
</template>

<script>
import vueScroll from 'vuescroll'
import examApi from '../../../api/exam'

export default {
  name: 'answerDetail',
  components: { vueScroll },
  data() {
    return {
      ops: {
        vuescroll: {},
        scrollPanel: {
          speed: 400
        },
        rail: {
          // size: '1px' // 滚动条大小
        },
        bar: {
          background: '#666',
          opacity: 0.6, // 滚动条透明度
          onlyShowBarOnScroll: false, // 是否只在滚动时bar
          showDelay: 1.5 // 鼠标离开该区域多长时间隐藏
        }
      },
      topicType: [],
      examInfo: {
        examName: '',
        endExamTime: '',
        spendExamTime: '',
        localTime: [],
        examTime: '',
        score: 0,
        questionCount: 0,
        level: 0,
        totalPoints: 0
      },
      optionValue: '',
      questionList: [],
      topicCount: 0,
      IsAnswerExam: this.$route.query.IsAnswerExam,
      userId: this.$route.query.userId,
      answerPaper: []
    }
  },
  mounted() {
    if (!this.$route.query.IsAnswerExam) {
      this.getExam()
    } else {
      this.getPersonExam()
    }
  },
  methods: {
    getTime(time) {
      //将时间戳转换成时间格式
      let date = new Date(time)
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      month = month < 10 ? '0' + month : month
      day = day < 10 ? '0' + day : day
      date = year + '-' + month + '-' + day
      return date
    },
    getExam() {
      // 获取试卷
      examApi.getExamByExamId(this.$route.query.examId).then(res => {
        const examList = res.res
        this.paperId = examList.paperId
        this.examInfo.examName = examList.paperName
        this.examInfo.questionCount = examList.questionAmount
        this.examInfo.level = examList.level
        this.examInfo.totalPoints = examList.totalPoints
        const examTopicList = examList.sectionVOS
        this.judgeExamType(examTopicList)
      })
    },
    selectChange(value, index) {
      this.examInfo.localTime.map(t => {
        if (t.value === value) {
          this.examInfo.examTime = t.label
          if (t.endTime) {
            this.examInfo.endExamTime = t.endTime
          } else {
            this.examInfo.endExamTime = ''
          }
        }
      })
      this.getAnswerExam(value, index)
    },
    getPersonExam() {
      // 获取answerPaperId
      this.answerPaper = []
      let userId = this.$route.query.userId
      let examId = this.$route.query.examId
      // let userId = 6
      // let examId = 648
      examApi.getPersonExamByExamId(userId, examId).then(res => {
        res.res.map(a => {
          this.answerPaper.push({
            startTime: a.startTime,
            answerPaperId: a.answerPaperId,
            submitTime: a.submitTime
          })
          this.examInfo.localTime.push({
            value: a.answerPaperId,
            label: a.startTime,
            endTime: a.submitTime,
            id: a.answerPaperId
          })
          this.optionValue = this.examInfo.localTime[0].value
          this.examInfo.examTime = this.getTime(this.answerPaper[0].startTime)
          this.examInfo.endExamTime = this.answerPaper[0].submitTime
        })
        this.getAnswerExam(this.examInfo.localTime[0].id, 0)
      })
    },
    getAnswerExam(anPaId, index) {
      // 获取考试已提交的试卷
      examApi.getExamByAnswerPaperId(anPaId).then(res => {
        const examList = res.res
        this.paperId = examList.paperId
        this.examInfo.examName = examList.paperName
        this.examInfo.questionCount = examList.questionAmount
        this.examInfo.level = examList.level
        this.examInfo.totalPoints = examList.totalPoints
        this.examInfo.score = examList.hasOwnProperty('userScore') ? examList.userScore : 0
        if (this.answerPaper[index].submitTime && this.answerPaper[index].startTime) {
          this.examInfo.spendExamTime = this.toHHmmss(this.answerPaper[index].submitTime - this.answerPaper[index].startTime)
        } else {
          this.examInfo.spendExamTime = ''
        }
        let examTopicList = examList.sectionVOS
        this.judgeExamType(examTopicList)
      })
    },
    toHHmmss(data) {
      let time
      let hours = parseInt((data % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      let minutes = parseInt((data % (1000 * 60 * 60)) / (1000 * 60))
      let seconds = (data % (1000 * 60)) / 1000
      time = (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes) + ':' + (seconds < 10 ? '0' + seconds : seconds)
      return time
    },
    getTimeByexpect(time) {
      // 时间 天 时 分 秒
      let theTime = parseInt(time) // 需要转换的时间秒
      let theTime1 = 0 // 分
      let theTime2 = 0 // 小时
      let theTime3 = 0 // 天
      if (theTime > 60) {
        theTime1 = parseInt(theTime / 60)
        theTime = parseInt(theTime % 60)
        if (theTime1 > 60) {
          theTime2 = parseInt(theTime1 / 60)
          theTime1 = parseInt(theTime1 % 60)
          if (theTime2 > 24) {
            //大于24小时
            theTime3 = parseInt(theTime2 / 24)
            theTime2 = parseInt(theTime2 % 24)
          }
        }
      }
      let result = ''
      if (theTime > 0) {
        result = '' + parseInt(theTime) + '秒'
      }
      if (theTime1 > 0) {
        result = '' + parseInt(theTime1) + '分' + result
      }
      if (theTime2 > 0) {
        result = '' + parseInt(theTime2) + '小时' + result
      }
      if (theTime3 > 0) {
        result = '' + parseInt(theTime3) + '天' + result
      }
      return result
    },
    getAnswerByExam(exam) {
      // 整理数据，视图显示
      exam.map(e => {
        if (e.name === '判断题') {
          e.questionType = 0
          let totalPoints = 0
          e.questionVOS.forEach(item => {
            totalPoints += item.points
            item.question.options = [
              { id: 0, content: true },
              { id: 1, content: false }
            ]
            if (item.resultStatus === 0) {
              // 正确
              item.color = '#3C88D3'
              item.result = true
            } else {
              item.color = '#EA4E18'
              item.result = false
            }
          })
          e.totalPoints = totalPoints
        } else if (e.name === '单选题') {
          let totalPoints = 0
          e.questionType = 1
          e.questionVOS.forEach(item => {
            totalPoints += item.points
            item.question.options.map((o, index) => {
              if (o.correct) {
                item.answer = index
              }
              if (item.myAnswer === o.content) {
                item.myAnswerByIndex = index
              }
            })
            if (item.resultStatus === 0) {
              // 正确
              item.color = '#3C88D3'
              item.result = true
            } else {
              item.color = '#EA4E18'
              item.result = false
            }
          })
          e.totalPoints = totalPoints
        } else if (e.name === '多选题') {
          let totalPoints = 0
          e.questionType = 2
          e.questionVOS.forEach(item => {
            totalPoints += item.points
            item.answer = []
            item.myAnswerByIndex = []
            item.question.options.forEach((q, index) => {
              if (q.correct) {
                item.answer.push(index)
              }
              if (item.myAnswer) {
                if (JSON.parse(item.myAnswer).includes(q.content)) {
                  item.myAnswerByIndex.push(index)
                }
              }
            })
            if (item.resultStatus === 0) {
              // 正确
              item.color = '#3C88D3'
              item.result = true
            } else {
              item.color = '#EA4E18'
              item.result = false
            }
          })
          e.totalPoints = totalPoints
        } else if (e.name === '填空题') {
          let totalPoints = 0
          e.questionType = 3
          e.questionVOS.forEach(item => {
            item.question.answer = []
            totalPoints += item.points
            item.question.blanks.forEach(b => {
              item.question.answer.push({ answer: b.blankAnswer })
            })
            if (item.resultStatus === 0) {
              // 正确
              item.color = '#3C88D3'
              item.result = true
            } else {
              item.color = '#EA4E18'
              item.result = false
            }
          })
          e.totalPoints = totalPoints
        } else if (e.name === '简答题') {
          let totalPoints = 0
          e.questionType = 4
          e.questionVOS.forEach(item => {
            totalPoints += item.points
            if (item.myAnswer === '' || !item.myAnswer) {
              item.color = '#EA4E18'
              item.result = false
            } else {
              if (item.question.answer === item.myAnswer) {
                // 正确
                item.color = '#3C88D3'
                item.result = true
              } else {
                item.color = '#EA4E18'
                item.result = false
              }
            }
          })
          e.totalPoints = totalPoints
        } else {
          let totalPoints = 0
          e.questionType = 5
          e.questionVOS.forEach(item => {
            totalPoints += item.points
            if (item.myAnswer === '' || !item.myAnswer) {
              item.color = '#EA4E18'
              item.result = false
            } else {
              item.myAnswer = JSON.parse(item.myAnswer).answer
              if (item.resultStatus === 0) {
                // 正确
                item.color = '#3C88D3'
                item.result = true
              } else {
                item.color = '#EA4E18'
                item.result = false
              }
            }
          })
          e.totalPoints = totalPoints
        }
      })
      this.questionList = exam
    },
    judgeExamType(data) {
      if (data[0].name === '默认大题') {
        return this.sortOutExam(data[0])
      } else {
        this.getTypeByOrder(data)
      }
    },
    getTypeByOrder(data) {
      // 题型整理
      data.map((t, index) => {
        switch (t.order) {
          case 1: {
            t.nameType = '一、' + t.name
            break
          }
          case 2: {
            t.nameType = '二、' + t.name
            break
          }
          case 3: {
            t.nameType = '三、' + t.name
            break
          }
          case 4: {
            t.nameType = '四、' + t.name
            break
          }
          case 5: {
            t.nameType = '五、' + t.name
            break
          }
        }
        this.IsAnswerExam === false
          ? t.questionVOS.map(qVos => {
              qVos.question = qVos.questionDetail
            })
          : ''
        if (index + 1 === t.order) {
          this.questionList[index] = t
        }
      })
      this.IsAnswerExam ? this.getAnswerByExam(data) : ''
    },
    sortOutExam(exam) {
      // 默认大题试卷
      // 设置默认大题 -> 题目的答案
      let totalPoints = 0
      exam.questionVOS.forEach((q, index) => {
        // 单选题
        totalPoints += q.points
        if (q.question.questionType === 1) {
          q.answer = ''
          q.questionType = 1
          if (!q.question.options) {
            return
          }
          q.question.options.map((o, index) => {
            if (o.correct) {
              q.answer = index
            }
            if (q.myAnswer === o.content) {
              q.myAnswerByIndex = index
            }
          })
          if (q.resultStatus === 0) {
            // 正确
            q.color = '#3C88D3'
            q.result = true
          } else {
            q.color = '#EA4E18'
            q.result = false
          }
        } else if (q.question.questionType === 0) {
          // 判断题
          q.questionType = 0
          q.options = [
            { id: 0, content: true },
            { id: 1, content: false }
          ]
          if (q.resultStatus === 0) {
            // 正确
            q.color = '#3C88D3'
            q.result = true
          } else {
            q.color = '#EA4E18'
            q.result = false
          }
        } else if (q.question.questionType === 2) {
          // 多选题
          q.questionType = 2
          let answer = []
          if (!q.question.options) {
            return
          }
          q.answer = []
          q.myAnswerByIndex = []
          q.question.options.forEach((q1, index) => {
            if (q1.correct) {
              answer.push(index)
            }
            if (q.myAnswer) {
              if (JSON.parse(q.myAnswer).includes(q1.content)) {
                q.myAnswerByIndex.push(index)
              }
            }
            // if (
            //   q.myAnswer !== '' &&
            //   JSON.parse(q.myAnswer).includes(q1.content)
            // ) {
            //   q.myAnswerByIndex.push(index)
            // }
          })
          if (q.resultStatus === 0) {
            // 正确
            q.color = '#3C88D3'
            q.result = true
          } else {
            q.color = '#EA4E18'
            q.result = false
          }
          q.answer = answer
        } else if (q.question.questionType === 3) {
          // 填空题
          q.questionType = 3
          let answer = []
          if (!q.question.blanks) {
            return
          }
          q.question.answer = []
          q.question.blanks.forEach(b => {
            q.question.answer.push({ answer: b.blankAnswer })
          })
          if (q.resultStatus === 0) {
            // 正确
            q.color = '#3C88D3'
            q.result = true
          } else {
            q.color = '#EA4E18'
            q.result = false
          }
          q.answer = answer
        } else if (q.question.questionType === 4) {
          q.questionType = 4
          if (q.myAnswer === '' || !q.myAnswer) {
            q.color = '#EA4E18'
            q.result = false
          } else {
            if (q.result) {
              // 正确
              q.color = '#3C88D3'
              q.result = true
            } else {
              q.color = '#EA4E18'
              q.result = false
            }
          }
        } else {
          q.questionType = 5
          if (q.myAnswer === '' || !q.myAnswer) {
            q.color = '#EA4E18'
            q.result = false
          } else {
            if (q.result) {
              // 正确
              q.color = '#3C88D3'
              q.result = true
            } else {
              q.color = '#EA4E18'
              q.result = false
            }
          }
        }
      })
      exam.totalPoints = totalPoints
      exam.nameType = `一、${exam.name}`
      this.questionList = []
      this.questionList.push(exam)
    }
  }
}
</script>

<style lang="less" scoped>
.flexC {
  display: flex;
  flex-direction: column;
}
ul {
  list-style: none;
}

.myAnswer {
  .hrStyle {
    margin: 30px 0;
    border: none;
    background: #afafaf;
    height: 1px;
    opacity: 0.2;
  }
  width: 100%;
  height: 100%;
  padding: 20px;
  text-align: left;
  max-height: 937px - 70px;

  .custom-breadcrumb {
    height: 20px;
  }

  .myAnswerList {
    padding-left: 50px;
    margin-left: 15px;
    margin-top: 30px;
    max-width: 1400px;
    min-width: 960px;
    max-height: calc(100% - 30px) !important;
    background: #fff;
    box-shadow: 0px 3px 5px rgba(175, 179, 181, 0.5);
    flex: auto;

    .examName {
      display: flex;
      align-items: center;
      margin: 20px 0 10px 0;
      height: 50px;

      span {
        font-size: 30px;
        line-height: 53px;
        font-weight: 400;
        color: #3a4e64;
      }

      .selectStyle {
        width: 150px;
        margin-left: 16px;
        min-width: 150px;
        font-size: 14px;
        color: #5e6470;

        ::v-deep .ivu-select-visible .ivu-select-selection {
          box-shadow: none;
          border: 2px solid #eaedf1;
        }
      }
    }

    .takeTime {
      height: 50px;
      display: flex;
      font-size: 14px;
      color: #333;
      line-height: 25px;
      font-weight: 400;
      margin-bottom: 50px;

      img {
        width: 22px;
        height: 22px;
        margin-right: 10px;
      }

      span {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-right: 40px;
      }
    }
    .examTopicList {
      padding-right: 50px;
      ul {
        font-size: 14px;
        line-height: 24px;
        font-weight: 300;
        color: #5e6470;
        .typeByTopic {
          margin-bottom: 60px;
        }

        .topicType {
          font-size: 16px;
          line-height: 28px;
          font-weight: 600;
          color: #333;
          margin-bottom: 15px;
        }

        .p1style {
          white-space: nowrap;
          display: flex;
          font-weight: 500;
          color: #333;
        }
        .p2Style {
          margin-top: 20px;
          display: flex;
        }

        p:last-child {
          color: #666;
        }

        .blueFont {
          color: #3c88d3;
        }

        .tagUl {
          color: #fff;
          font-size: 14px;
          display: flex;
          line-height: 25px;
          font-weight: 300;
          /*margin-bottom: 20px;*/
          li {
            text-align: center;
            min-width: 150px;
            min-height: 25px;
            background: #5b606e;
            border-radius: 8px;
            margin-right: 5px;
          }

          li:nth-child(1) {
            text-align: left;
            min-width: 30px;
            margin-right: 15px;
            background: none;
            border-radius: 0;
            color: #666;
          }
        }
      }
    }
  }
  /*.typeByTopic {*/
  /*  padding-right: 50px;*/
  /*  p {*/
  /*    white-space: pre;*/
  /*  }*/
  /*}*/
}
</style>
